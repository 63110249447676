import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";
import {
  Box,
  Button,
  FormControl,
  Select,
  MenuItem,
  IconButton,
  Modal,
  CircularProgress,
  Typography,
} from "@mui/material";
import * as XLSX from "xlsx";
import { v4 as uuidv4 } from "uuid";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import localeTexts from "../locales/localeText";

const api_url = process.env.REACT_APP_LOCAL_URL;

function GridData({ useLabels }) {
  const { i18n } = useTranslation();
  const location = useLocation();
  const {
    selectedBp: initialSelectedBp,
    selectedProjectNumber: initialSelectedProjectNumber,
  } = location.state || {};
  const [selectedRow, setSelectedRow] = useState(null); // 모달에 표시될거
  const [isModalOpen, setIsModalOpen] = useState(false); // 모달 열기ㅣ
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [projects, setProjects] = useState([]);
  const [bps, setBps] = useState([]);
  const [selectedProject, setSelectedProject] = useState(
    initialSelectedProjectNumber || ""
  );
  const [originalRows, setOriginalRows] = useState([]);
  const [selectedBp, setSelectedBp] = useState(initialSelectedBp || "");
  const [existingRecords, setExistingRecords] = useState([]);
  const [fileInputKey, setFileInputKey] = useState(Date.now());
  const [isDataSaved, setIsDataSaved] = useState(false);
  const [globalLineItemColumns, setGlobalLineItemColumns] = useState([]);
  const [savedChanges, setSavedChanges] = useState([]);
  const [loading, setLoading] = useState(false);
  const [resultModalOpen, setResultModalOpen] = useState(false); // 결과 모달 상태 추가
  const [resultCounts, setResultCounts] = useState({
    recordAdditions: 0,
    recordUpdates: 0,
    lineItemAdditions: 0,
    lineItemUpdates: 0,
  });

  const handleResultModalClose = () => {
    setResultModalOpen(false);
    setResultCounts(null);
  };

  const handleOpenModal = (row) => {
    setSelectedRow(row);
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const [errorMessages, setErrorMessages] = useState([]);

  const apiRef = useGridApiRef();

  // 라인아이템 추가
  const handleAddLineItem = (parentRowId) => {
    setRows((prevRows) =>
      prevRows.map((row) => {
        if (row.id === parentRowId) {
          const newLineItem = {
            id: uuidv4(),
            ...globalLineItemColumns.reduce((acc, column) => {
              if (column !== "id") {
                acc[column] = "";
              }
              return acc;
            }, {}),
          };

          const updatedRow = {
            ...row,
            _bp_lineitems: [...(row._bp_lineitems || []), newLineItem],
          };

          if (selectedRow && selectedRow.id === parentRowId) {
            setSelectedRow(updatedRow);
          }
          return updatedRow;
        }
        return row;
      })
    );
  };

  useEffect(() => {
    if (columns.length > 0) {
      setColumns((prevColumns) =>
        prevColumns.map((col) =>
          col.field === "viewLineItems"
            ? { ...col, headerName: "라인 아이템 조회" }
            : col
        )
      );
    }
  }, [columns.length]);

  // 행 추가
  const handleAddRow = () => {
    const newRow = {
      id: uuidv4(),
      ...columns.reduce((acc, column) => {
        acc[column.field] = "";
        return acc;
      }, {}),
    };

    setRows((prevRows) => [...prevRows, newRow]);
    const savedDataKey = `project_${selectedProject}_bp_${selectedBp}`;
    const savedData = localStorage.getItem(savedDataKey);
    const parsedData = savedData ? JSON.parse(savedData) : { records: [] };

    parsedData.records.push(newRow);
    localStorage.setItem(savedDataKey, JSON.stringify(parsedData));
  };

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get(`/api/getProjects`);
        const companyWorkspace = {
          project_name: "Company_workspace",
          project_number: "0",
        };
        setProjects([companyWorkspace, ...response.data]);
      } catch (error) {
        console.error(
          "프로젝트 목록을 불러오는 중 오류가 발생했습니다.",
          error
        );
      }
    };
    fetchProjects();
  }, []);

  // 선택된 프로젝트에 따라 BP 목록 가져오기
  useEffect(() => {
    if (selectedProject) {
      const fetchBpList = async () => {
        try {
          const response = await axios.get(`/api/getBpList`, {
            params: { project_number: selectedProject },
          });
          setBps(response.data);
        } catch (error) {
          console.error("BP 목록을 불러오는 중 오류가 발생했습니다.", error);
        }
      };
      fetchBpList();
    }
  }, [selectedProject]);

  // BP 데이터 가져오기
  const fetchData = async () => {
    if (!selectedBp) return;

    try {
      const response = await axios.post(`/api/getBpData`, {
        bpname: selectedBp,
        project_number: selectedProject,
      });
      const data = response?.data?.data || [];

      const sampleRecordWithLineItems = data.find(
        (item) => item._bp_lineitems && item._bp_lineitems.length > 0
      );

      if (sampleRecordWithLineItems) {
        const lineItemKeys = Object.keys(
          sampleRecordWithLineItems._bp_lineitems[0]
        );
        setGlobalLineItemColumns(lineItemKeys);
      }

      const formattedRows = data.map((item) => ({
        id: item.id || uuidv4(),
        ...item,
      }));

      setRows(formattedRows);

      const lineItemFields = sampleRecordWithLineItems
        ? Object.keys(sampleRecordWithLineItems._bp_lineitems[0])
        : [];
      const labelResponse = await axios.post(`/api/getFieldLabels`, {
        dataElements: lineItemFields,
      });
      const lineItemLabels = labelResponse.data.labels;

      const dynamicLineItemColumns = lineItemFields.map((key) => ({
        field: key,
        headerName: useLabels ? lineItemLabels[key] || key : key,
        width: 150,
        editable: true,
        align: "center",
        headerAlign: "center",
        flex: 1,
        minWidth: 150,
      }));

      const hasLineItems = formattedRows.some(
        (row) => row._bp_lineitems && row._bp_lineitems.length > 0
      );

      const dataElements = Object.keys(data[0] || {}).filter(
        (key) => key !== "_bp_lineitems"
      );
      const recordLabelResponse = await axios.post(`/api/getFieldLabels`, {
        dataElements,
      });
      const recordLabels = recordLabelResponse.data.labels;

      const dynamicColumns = [
        ...dataElements.map((key) => ({
          field: key,
          headerName: useLabels ? recordLabels[key] || key : key,
          width: 150,
        })),
        ...(hasLineItems
          ? [
              {
                field: "viewLineItems",
                headerName: "라인 아이템 조회",
                renderCell: (params) => (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleOpenModal(params.row)}
                  >
                    라인 아이템 조회
                  </Button>
                ),
                width: 150,
                align: "center",
                headerAlign: "center",
              },
            ]
          : []),
      ];

      setColumns(dynamicColumns);
      setIsDataSaved(false);
      setExistingRecords(formattedRows);
      setGlobalLineItemColumns(dynamicLineItemColumns);
      setOriginalRows(formattedRows);
    } catch (error) {
      console.error("BP 데이터 가져오기 오류:", error);
    }
  };
  // useLabels가 변경될 때 fetchData를 자동 호출
  useEffect(() => {
    if (selectedBp) {
      fetchData();
    }
  }, [useLabels]); // useLabels 변경 시 데이터 새로 고침

  const handleGetData = () => {
    if (selectedBp) {
      fetchData();
      setResultCounts({
        recordAdditions: 0,
        recordUpdates: 0,
        lineItemAdditions: 0,
        lineItemUpdates: 0,
      });
      setSavedChanges([]);
    }
  };

  const handleProjectChange = (event) => {
    setSelectedProject(event.target.value);
    setSelectedBp("");
    setRows([]);
    setColumns([]);
  };

  const handleBpChange = (event) => {
    setSelectedBp(event.target.value);
  };

  // 라인아이템 엑셀 다운로드
  const downloadLineItemExcelFile = (lineItems, selectedBp, recordNo) => {
    if (!lineItems || lineItems.length === 0) {
      alert("다운로드할 라인아이템이 없습니다.");
      return;
    }
    // "삭제 여부" 컬럼 추가
    const updateLineItems = lineItems.map((item) => ({
      ...item,
      "삭제 여부": "",
    }));

    const workbook = XLSX.utils.book_new();
    const lineItemsWorksheet = XLSX.utils.json_to_sheet(updateLineItems);
    XLSX.utils.book_append_sheet(workbook, lineItemsWorksheet, "Line Items");

    XLSX.writeFile(
      workbook,
      `${selectedProject}_${selectedBp}_${recordNo}_lineitems.xlsx`
    );
    alert("라인아이템 다운로드가 완료되었습니다.");
  };

  // 라인아이템 엑셀 업로드
  const handleLineItemExcelUpload = (event, selectedRow) => {
    const file = event.target.files[0];
    if (!file) {
      alert("파일이 선택되지 않았습니다.");
      return;
    }

    const fileName = file.name.split(".").slice(0, -1).join(".");
    const [projectPart, bpPart, record_no] = fileName.split("_");

    if (!projectPart || !bpPart || !record_no) {
      alert(
        "파일 이름이 형식에 맞지 않습니다. '프로젝트번호_BP이름_레코드번호.xlxs' 형식이여야 합니다."
      );
      return;
    }

    setSelectedProject(projectPart);
    setSelectedBp(bpPart);

    const reader = new FileReader();
    reader.onload = (event) => {
      try {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
        const lineItems = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });

        // 헤더 검사
        const headers = lineItems[0];
        if (!headers.includes("LineAutoSeq")) {
          alert("엑셀 파일 형식이 잘못되었습니다. 필수 헤더가 누락되었습니다.");
          return;
        }

        // "삭제 여부" 확인하여 삭제 작업 수행
        const updatedLineItems = lineItems
          .slice(1)
          .map((row) => {
            const itemData = {};
            headers.forEach((header, i) => {
              itemData[header] = row[i];
            });

            // 삭제 여부가 "Y"인 경우 삭제 API 요청
            if (itemData["삭제 여부"]?.toLowerCase() === "y") {
              axios.post(`/api/deleteLineItem`, {
                bpName: bpPart,
                projectNumber: projectPart,
                targetItem: itemData.LineAutoSeq,
                targetRecord: record_no,
              });
              return null; // 삭제된 아이템은 null로 설정
            }
            return itemData;
          })
          .filter((item) => item !== null); // 삭제되지 않은 항목만 필터링

        const updatedSelectedRow = {
          ...selectedRow,
          _bp_lineitems: updatedLineItems,
        };
        setSelectedRow(updatedSelectedRow);

        setRows((prevRows) =>
          prevRows.map((row) =>
            row.id === selectedRow.id ? updatedSelectedRow : row
          )
        );

        alert("라인아이템 업로드가 완료되었습니다.");
      } catch (error) {
        console.error("엑셀 파일을 읽는 중 오류 발생:", error);
        alert("엑셀 파일을 읽는 중 오류가 발생했습니다.");
      }
    };

    reader.readAsArrayBuffer(file);
  };

  // 엑셀 다운로드
  const downloadExcelFile = (data, selectedBp) => {
    const mainRecords = [];
    const lineItems = [];

    data.forEach((row) => {
      mainRecords.push({
        ...row,
        _bp_lineitems: row._bp_lineitems ? row._bp_lineitems.length : 0,
      });

      if (row._bp_lineitems && row._bp_lineitems.length > 0) {
        row._bp_lineitems.forEach((lineItem) => {
          lineItems.push({
            ...lineItem,
            record_no: row.record_no,
            "삭제 여부": "",
          });
        });
      }
    });

    const workbook = XLSX.utils.book_new();

    // 첫번째 시트 레코드
    const mainWorksheet = XLSX.utils.json_to_sheet(mainRecords);
    XLSX.utils.book_append_sheet(
      workbook,
      mainWorksheet,
      selectedBp || "Main Records"
    );

    // 두번째 시트 라인아이템
    if (lineItems.length > 0) {
      const lineItemsWorksheet = XLSX.utils.json_to_sheet(lineItems);
      XLSX.utils.book_append_sheet(workbook, lineItemsWorksheet, "Line Items");
    }

    // 파일 이름 projectNumber_bpName.xlxs
    XLSX.writeFile(workbook, `${selectedProject}_${selectedBp}.xlsx`);
  };

  // 엑셀 업로드
  const handleExcelUpload = (event) => {
    const file = event.target.files[0];
    if (!file) {
      console.error("파일이 선택되지 않았습니다.");
      return;
    }

    // 파일 이름에서 프로젝트 번호와 BP 이름 추출
    const fileName = file.name.split(".").slice(0, -1).join(".");
    const parts = fileName.split("_");
    const projectPart = parts[0];
    const bpPart = parts[1];

    if (parts.length !== 2) {
      alert(
        "파일 이름이 형식에 맞지 않습니다. '프로젝트번호_BP이름.xlsx' 형식이어야 합니다."
      );
      return;
    }

    // 프로젝트 번호와 BP 이름이 포함되어 있어야 함
    if (!projectPart || !bpPart) {
      alert(
        "파일 이름이 형식에 맞지 않습니다. '프로젝트번호_BP이름.xlsx' 형식이어야 합니다."
      );
      return;
    }

    setSelectedProject(projectPart);
    setSelectedBp(bpPart);

    const reader = new FileReader();

    reader.onload = (event) => {
      try {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: "array" });

        const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });

        const secondSheet = workbook.Sheets[workbook.SheetNames[1]];
        const lineItemData = XLSX.utils.sheet_to_json(secondSheet, {
          header: 1,
        });

        const lineItemsByRecord = {};
        const lineItemHeaders = lineItemData[0];

        lineItemData.slice(1).forEach((lineItemRow) => {
          const lineItemObj = {};
          lineItemHeaders.forEach((header, i) => {
            lineItemObj[header] = lineItemRow[i];
          });

          const recordNo = lineItemObj.record_no;
          if (!lineItemsByRecord[recordNo]) {
            lineItemsByRecord[recordNo] = [];
          }
          if (lineItemObj["삭제 여부"] === "Y") {
            axios.post(`/api/deleteLineItem`, {
              bpName: bpPart,
              projectNumber: projectPart,
              targetItem: lineItemObj.LineAutoSeq,
              targetRecord: recordNo,
            });
          } else {
            lineItemsByRecord[recordNo].push(lineItemObj);
          }
        });

        // 엑셀 파일에서 행 id (uuid) 그리드에 안보이게하기
        const headers = jsonData[0].filter(
          (header) => header !== "id" && header !== "삭제 여부"
        );

        const rows = jsonData.slice(1).map((row) => {
          const rowData = {};
          headers.forEach((header, i) => {
            rowData[header] = row[i + 1];
          });

          // 라인아이템
          rowData._bp_lineitems = lineItemsByRecord[rowData.record_no] || []; // record_no연결
          rowData.id = uuidv4();
          console.log("Row Data : ", rowData);
          return rowData;
        });

        setRows([]);
        setColumns([]);
        setTimeout(() => {
          setRows(rows);

          const dynamicColumns = headers
            .filter((header) => header !== "_bp_lineitems")
            .map((header) => ({
              field: header,
              headerName: header,
              width: 150,
            }));

          if (Object.keys(lineItemsByRecord).length > 0) {
            dynamicColumns.push({
              field: "viewLineItems",
              headerName: "라인아이템 조회",
              renderCell: (params) => (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => handleOpenModal(params.row)}
                >
                  라인 아이템 조회
                </Button>
              ),
            });
          }

          setColumns(dynamicColumns);
          setRows(rows);
        }, 0);

        setFileInputKey(Date.now());
      } catch (error) {
        console.error("엑셀 파일을 읽는 중 오류 발생:", error);
      }
    };

    reader.readAsArrayBuffer(file);
  };

  // 저장버튼 눌렀을 때
  const saveData = () => {
    const { changedRows, counts } = getChangedRows(originalRows, rows);

    if (counts.duplicateRecordNos.length > 0) {
      console.log("중복된 레코드 번호:", counts.duplicateRecordNos);
      return; // 중복된 레코드 번호가 있을 때는 저장하지 않고 종료
    }

    const updatedSavedChanges = [...savedChanges, ...changedRows];

    // 각 저장 시마다 변경된 개수만을 반영하여 counts를 업데이트
    const updatedCounts = {
      recordAdditions:
        (resultCounts?.recordAdditions || 0) + counts.recordAdditions,
      recordUpdates: (resultCounts?.recordUpdates || 0) + counts.recordUpdates,
      lineItemAdditions:
        (resultCounts?.lineItemAdditions || 0) + counts.lineItemAdditions,
      lineItemUpdates:
        (resultCounts?.lineItemUpdates || 0) + counts.lineItemUpdates,
    };

    const nonEmptyRow = rows.filter((row) => {
      // row_id를 제외하고 값이 있는지 확인
      return Object.keys(row).some(
        (key) => key !== "id" && row[key] !== "" && row[key] !== undefined
      );
    });

    // 각 row의 _bp_lineitems를 검사하여 빈 라인아이템 제거
    const cleanedRows = nonEmptyRow.map((row) => {
      if (row._bp_lineitems && row._bp_lineitems.length > 0) {
        row._bp_lineitems = row._bp_lineitems.filter((lineItem) => {
          return Object.keys(lineItem).some(
            (key) =>
              key !== "id" &&
              lineItem[key] !== "" &&
              lineItem[key] !== undefined
          );
        });
      }
      return row;
    });

    setRows(cleanedRows); // 상태 업데이트
    setSavedChanges(updatedSavedChanges);
    setResultCounts(updatedCounts);

    console.log("변경된 행들:", updatedSavedChanges);
    console.log("레코드 추가 개수:", updatedCounts.recordAdditions);
    console.log("레코드 수정 개수:", updatedCounts.recordUpdates);
    console.log("라인아이템 추가 개수:", updatedCounts.lineItemAdditions);
    console.log("라인아이템 수정 개수:", updatedCounts.lineItemUpdates);

    if (changedRows.length > 0) {
      console.log("변경된 데이터 : ", updatedSavedChanges);
      alert("변경된 데이터가 저장되었습니다.");
      setOriginalRows(rows);
    } else {
      alert("변경된 데이터가 없습니다.");
    }
    setIsDataSaved(true); // 전송 버튼 활성화
  };

  const uploadToUnifier = async () => {
    console.log("upload To Unifier : ", savedChanges);
    if (!savedChanges || savedChanges.length === 0) {
      alert("전송할 데이터가 없습니다.");
      return;
    }

    const confirmSend = window.confirm(
      `정말로 ${
        projects.find((p) => p.project_number === selectedProject)?.project_name
      }의 ${selectedBp} 데이터를 전송하시겠습니까?`
    );

    if (!confirmSend) {
      console.log("사용자가 전송을 취소했습니다.");
      return;
    }

    try {
      setLoading(true);

      const requestBody = {
        options: {
          projectNumber: selectedProject,
          bpName: selectedBp,
        },
        data: savedChanges,
      };

      const response = await axios.post(`/api/sendData`, requestBody);

      if (response.status === 200) {
        console.log("데이터 전송 성공");
        setIsDataSaved(false);
        setSavedChanges([]);
        setResultModalOpen(true);
        console.log(resultCounts);

        await fetchData();
      } else {
        const issues = response.data.failedRecords.flatMap((record) =>
          record.error ? record.error.split(".") : []
        );
        const filteredIssues = issues.slice(0, -1);

        const formattedMessage = `필수 입력값이 빠졌습니다.<br /><br />${filteredIssues.join(
          "<br />"
        )}`;
        setErrorMessages([formattedMessage]);
        console.log("에러 메시지 :", errorMessages);
        console.error("데이터 전송 실패", response);
      }
    } catch (error) {
      console.error("데이터 전송 중 오류 발생:", error);

      // 오류 응답 데이터에서 failedRecords를 추출하여 설정
      const issues = error.response?.data?.failedRecords?.flatMap((record) =>
        record.error ? record.error.split(".") : []
      );

      // 마지막 항목 제외
      const filteredIssues = issues.slice(0, -1);
      console.log(filteredIssues);

      if (filteredIssues && filteredIssues.length > 0) {
        const formattedMessage = `필수 입력값이 빠졌습니다.<br /><br />${filteredIssues.join(
          "<br />"
        )}`;
        console.log(formattedMessage);
        setErrorMessages([formattedMessage]);
      } else {
        setErrorMessages(["데이터 전송 중 오류가 발생했습니다."]); // 기본 오류 메시지
      }

      if (issues && issues.length > 0) {
        const formattedMessage = `필수 입력값이 빠졌습니다.<br /><br />${filteredIssues.join(
          "<br />"
        )}`;
        setErrorMessages([formattedMessage]);
      } else {
        setErrorMessages(["데이터 전송 중 오류가 발생했습니다."]); // 기본 오류 메시지
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isModalOpen && selectedRow) {
      const updatedRow = rows.find((row) => row.id === selectedRow.id);
      if (updatedRow) {
        setSelectedRow(updatedRow);
      }
    }
  }, [isModalOpen, rows, selectedRow]);

  const handleLineItemDelete = async (parentRow, LineAutoSeq) => {
    const confirmDelete = window.confirm("정말 삭제하시겠습니까?");
    if (!confirmDelete) {
      return;
    }

    try {
      const response = await axios.post(`/api/deleteLineItem`, {
        bpName: selectedBp,
        projectNumber: selectedProject,
        targetItem: LineAutoSeq,
        targetRecord: parentRow.record_no,
      });

      // API 호출 성공 시 해당 아이템 삭제np
      if (response.status === 200) {
        const updatedLineItems = parentRow._bp_lineitems.filter(
          (item) => item.LineAutoSeq !== LineAutoSeq
        );

        // 부모 행의 _bp_lineitems 업데이트
        const updatedRows = rows.map((row) =>
          row.id === parentRow.id
            ? { ...row, _bp_lineitems: updatedLineItems }
            : row
        );

        // 선택된 행 상태도 업데이트
        setRows(updatedRows);
        setSelectedRow((prevSelectedRow) => ({
          ...prevSelectedRow,
          _bp_lineitems: updatedLineItems,
        }));

        alert(
          `레코드 번호 ${parentRow.record_no}의 라인아이템 ${LineAutoSeq}을(를) 삭제했습니다.`
        );
      } else {
        alert("삭제 실패:", { message: response.data.message });
      }
    } catch (error) {
      if (error.response && error.response.data) {
        console.error("삭제 중 오류 발생:", error.response.data);
        alert("삭제 중 오류가 발생했습니다:", {
          message: error.response.data.message,
        });
      } else {
        console.error("삭제 중 오류 발생:", error);
        alert("삭제 중 오류가 발생했습니다:");
      }
    }
  };
  const getChangedRows = (originalData, updatedData) => {
    const counts = {
      recordAdditions: 0,
      recordUpdates: 0,
      lineItemAdditions: 0,
      lineItemUpdates: 0,
      duplicateRecordNos: [],
    };

    const existingRecordNos = new Set(originalData.map((row) => row.record_no));

    const changedRows = updatedData.filter((updatedRow) => {
      // updatedRow가 존재하는지 확인
      if (!updatedRow) return false;

      // originalRow를 찾기
      const originalRow = originalData.find(
        (row) =>
          row.id === updatedRow.id && row.record_no === updatedRow.record_no
      );

      // originalRow가 존재하지 않으면 레코드 추가로 처리
      let rowChanged = false;

      if (!originalRow) {
        const hasData = Object.keys(updatedRow).some(
          (key) =>
            key !== "id" &&
            updatedRow[key] !== "" &&
            updatedRow[key] !== undefined
        );

        if (hasData) {
          // 중복된 record_no 검사
          if (existingRecordNos.has(updatedRow.record_no)) {
            alert(
              `레코드 번호 ${updatedRow.record_no}는 이미 존재합니다. 다른 번호를 입력해주세요.`
            );
            counts.duplicateRecordNos.push(updatedRow.record_no);
            return false;
          }

          counts.recordAdditions++;
          existingRecordNos.add(updatedRow.record_no);
          rowChanged = true;
        } else {
          return false; // 빈 행은 제외
        }
      }

      // 기존 행이 존재하면, 변경된 필드가 있는지 확인
      if (originalRow) {
        const isRecordChanged = Object.keys(updatedRow).some(
          (key) =>
            key !== "_bp_lineitems" && updatedRow[key] !== originalRow[key]
        );

        if (isRecordChanged) {
          counts.recordUpdates++;
          rowChanged = true;
        }

        // 라인아이템 비교
        if (updatedRow._bp_lineitems) {
          updatedRow._bp_lineitems.forEach((newLineItem) => {
            const existingLineItem = originalRow?._bp_lineitems
              ? originalRow._bp_lineitems.find(
                  (item) => item.LineAutoSeq === newLineItem.LineAutoSeq
                )
              : null;

            if (!existingLineItem) {
              counts.lineItemAdditions++;
              rowChanged = true;
            } else {
              const isLineItemChanged = Object.keys(newLineItem).some((key) => {
                return newLineItem[key] !== existingLineItem[key];
              });
              if (isLineItemChanged) {
                counts.lineItemUpdates++;
                rowChanged = true;
              }
            }
          });
        }
      }

      return rowChanged;
    });

    return { changedRows, counts };
  };

  return (
    <div style={{ padding: "16px", width: "auto", margin: "0 auto" }}>
      {loading && (
        <Modal open={loading}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              borderRadius: 2,
              p: 2,
              boxShadow: 24,
            }}
          >
            <CircularProgress />
            <Typography variant="body1" sx={{ ml: 2 }}>
              전송 중입니다...
            </Typography>
          </Box>
        </Modal>
      )}

      {/* 결과 모달 */}
      <Modal open={resultModalOpen} onClose={handleResultModalClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            width: 300,
            textAlign: "center",
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" gutterBottom>
            전송 결과
          </Typography>
          {resultCounts ? (
            <>
              <Typography>
                레코드 추가: {resultCounts.recordAdditions}건
              </Typography>
              <Typography>
                레코드 수정: {resultCounts.recordUpdates}건
              </Typography>
              <Typography>
                라인아이템 추가: {resultCounts.lineItemAdditions}건
              </Typography>
              <Typography>
                라인아이템 수정: {resultCounts.lineItemUpdates}건
              </Typography>
            </>
          ) : (
            <Typography>결과를 불러오는 중...</Typography>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={handleResultModalClose}
            sx={{ mt: 2 }}
          >
            닫기
          </Button>
        </Box>
      </Modal>
      <Modal
        open={errorMessages.length > 0}
        onClose={() => setErrorMessages([])}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            width: 300,
            textAlign: "center",
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" gutterBottom>
            오류 메시지
          </Typography>
          <Typography
            sx={{ mb: 1, textAlign: "center" }}
            dangerouslySetInnerHTML={{ __html: errorMessages[0] }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={() => setErrorMessages([])}
            sx={{ mt: 2 }}
          >
            닫기
          </Button>
        </Box>
      </Modal>

      <Box
        sx={{
          display: "flex",
          gap: 1,
          marginTop: 3,
          marginBottom: 3,
          alignItems: "center",
        }}
      >
        <Box
          sx={{ display: "flex", gap: 1, flexGrow: 1, alignItems: "center" }}
        >
          <FormControl
            variant="outlined"
            sx={{ minWidth: 120, height: "32px" }}
          >
            <Select
              labelId="project-select-label"
              value={selectedProject}
              onChange={handleProjectChange}
              displayEmpty
              sx={{ height: "32px", fontSize: "0.8rem", textAlign: "center" }}
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return <span style={{ color: "#aaa" }}>프로젝트</span>;
                }
                return projects.find(
                  (project) => project.project_number === selected
                )?.project_name;
              }}
            >
              {projects.map((project) => (
                <MenuItem
                  key={project.project_number}
                  value={project.project_number}
                  sx={{ fontSize: "0.8rem" }}
                >
                  {project.project_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl
            variant="outlined"
            sx={{ minWidth: 120, height: "32px" }}
            disabled={!bps.length}
          >
            <Select
              labelId="bp-select-label"
              value={selectedBp}
              onChange={handleBpChange}
              displayEmpty
              sx={{ height: "32px", fontSize: "0.8rem" }}
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return <span style={{ color: "#aaa" }}>BP</span>;
                }
                return bps.find((bp) => bp.bpname === selected)?.bpname;
              }}
            >
              {bps.map((bp) => (
                <MenuItem
                  key={bp.bpname}
                  value={bp.bpname}
                  sx={{ fontSize: "0.8rem" }}
                >
                  {bp.bpname}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Button
            variant="outlined"
            onClick={handleGetData}
            disabled={!selectedBp}
            sx={{ height: "32px", fontSize: "0.8rem", textAlign: "center" }}
          >
            get
          </Button>
          <Button
            variant="outlined"
            component="label"
            sx={{ height: "32px", fontSize: "0.8rem", textAlign: "center" }}
          >
            엑셀 업로드
            <input
              type="file"
              key={fileInputKey}
              hidden
              accept=".xlsx, .xls"
              onChange={handleExcelUpload}
            />
          </Button>
          <Button
            variant="outlined"
            onClick={() => downloadExcelFile(rows, selectedBp)}
            disabled={rows.length === 0}
            sx={{ height: "32px", fontSize: "0.8rem", textAlign: "center" }}
          >
            엑셀 다운로드
          </Button>
          <Button
            variant="outlined"
            disabled={rows.length === 0}
            onClick={handleAddRow}
            sx={{ height: "32px", fontSize: "0.8rem", textAlign: "center" }}
          >
            레코드 추가
          </Button>
        </Box>
      </Box>

      <div style={{ height: 750, width: "100%", marginTop: 5 }}>
        <DataGridPro
          apiRef={apiRef}
          rows={rows}
          columns={columns.map((col) => ({
            ...col,
            editable: true,
            align: "center",
            headerAlign: "center",
            flex: 1,
            minWidth: 150,
          }))}
          pageSizeOptions={[25, 50, 100]}
          pagination
          localeText={localeTexts[i18n.language]}
          style={{ width: "100%", height: "100%" }}
          processRowUpdate={(newRow, oldRow) => {
            const updatedRows = rows.map((row) =>
              row.id === oldRow.id ? newRow : row
            );
            setRows(updatedRows);
            return newRow;
          }}
          experimentalFeatures={{ newEditingApi: true }}
          onProcessRowUpdateError={(error) =>
            console.error("Row update error:", error)
          }
        />
      </div>
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            height: "80%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <IconButton
            onClick={handleCloseModal}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
            }}
          >
            <CloseIcon />
          </IconButton>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
              gap: 2,
              paddingX: 2,
            }}
          >
            <div style={{ fontSize: "1rem", fontWeight: "bold" }}>
              BP: {selectedBp} | Record_no: {selectedRow?.record_no}
            </div>
            <Box sx={{ display: "flex", gap: 1 }}>
              <Button variant="outlined" component="label">
                엑셀 업로드
                <input
                  type="file"
                  hidden
                  accept=".xlsx, .xls"
                  onChange={(event) =>
                    handleLineItemExcelUpload(event, selectedRow)
                  }
                />
              </Button>
              <Button
                variant="outlined"
                onClick={() =>
                  downloadLineItemExcelFile(
                    selectedRow._bp_lineitems,
                    selectedBp,
                    selectedRow.record_no
                  )
                }
              >
                엑셀 다운로드
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => handleAddLineItem(selectedRow.id)}
              >
                라인 아이템 추가
              </Button>
              <Button variant="outlined" color="primary" onClick={saveData}>
                저장
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={uploadToUnifier}
                disabled={!isDataSaved}
              >
                전송
              </Button>
            </Box>
          </Box>

          <Box
            sx={{
              flex: 1,
              overflowY: "auto", // 그리드 내부에서만 스크롤,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {selectedRow &&
            selectedRow._bp_lineitems &&
            selectedRow._bp_lineitems.length > 0 ? (
              <DataGridPro
                rows={selectedRow._bp_lineitems.map((item, index) => ({
                  id: item.LineAutoSeq || index,
                  ...item,
                }))}
                columns={[
                  {
                    width: 100,
                    align: "center",
                    renderCell: (cellParams) => (
                      <IconButton
                        variant="contained"
                        onClick={() =>
                          handleLineItemDelete(selectedRow, cellParams.row.id)
                        }
                      >
                        <DeleteOutlineIcon />
                      </IconButton>
                    ),
                  },
                  ...globalLineItemColumns,
                ]}
                pageSizeOptions={[25, 50, 100]}
                pagination
                processRowUpdate={(newRow, oldRow) => {
                  // 새로 업데이트된 라인아이템을 처리하는 함수
                  const updatedLineItems = selectedRow._bp_lineitems.map(
                    (item) =>
                      item.LineAutoSeq === oldRow.LineAutoSeq ? newRow : item
                  );

                  // 업데이트된 라인아이템을 selectedRow에 반영
                  const updatedSelectedRow = {
                    ...selectedRow,
                    _bp_lineitems: updatedLineItems,
                  };
                  setSelectedRow(updatedSelectedRow);

                  // 전체 rows에서도 업데이트
                  setRows((prevRows) =>
                    prevRows.map((row) =>
                      row.id === selectedRow.id
                        ? { ...row, _bp_lineitems: updatedLineItems }
                        : row
                    )
                  );

                  return newRow;
                }}
                experimentalFeatures={{ newEditingApi: true }}
                editable={true} // 수정 가능 여부를 isEditable로 제어
                onProcessRowUpdateError={(error) =>
                  console.error("Row update error:", error)
                }
              />
            ) : (
              <p> 라인 아이템이 없습니다.</p>
            )}
          </Box>
        </Box>
      </Modal>
      {rows.length > 0 && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: 2,
            marginTop: 2,
          }}
        >
          <Button variant="outlined" color="primary" onClick={saveData}>
            저장
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={uploadToUnifier}
            disabled={!isDataSaved}
          >
            전송
          </Button>
        </Box>
      )}
    </div>
  );
}

export default GridData;
